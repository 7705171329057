@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900');

@import './scroll.scss';
@import './themeAndVariable.scss';
@import './reset.scss';
@import './base.scss';

body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 19px;
}

span,
p,
label,
button {
  font-size: 0.875rem;
  line-height: 19px;
  margin: 0px;
}

code {
  font-family: 'Poppins';
}

.row {
  --bs-gutter-x: 0 !important;
}

.col-lg-6, .col-12 {
  padding: 15px !important;
}