.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #f5f7fb;
}

.content-wrap {
  flex: 1;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 40, 100, 0.12);

  .header-menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
  }

  .li-item {
    list-style-type: none;
    padding: 10px 0;
    .nav-link {
      color: #284e64;
      &.active-link {
        color: #0d6efd;
        border-bottom: 1px solid #0d6efd;
      }
    }
  }
  .logo-info-wrapper {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer {
  background: #fff;
  border-top: 1px solid rgba(0, 40, 100, 0.12);
  padding: 1.5rem 0;
  color: #9aa0ac;
  display: flex;
  justify-content: center;
}

.menu_toggle {
  width: 22px;
  height: 22px;
  position: relative;
  cursor: pointer;
  display: block;
  .hamburger {
    position: absolute;
    height: 100%;
    width: 100%;
    display: block;
    span {
      width: 0%;
      height: 2px;
      position: relative;
      top: 0;
      left: 0;
      margin: 4px 0;
      display: block;
      background: #516077;
      border-radius: 3px;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
      &:nth-child(1) {
        transition-delay: 0s;
      }
      &:nth-child(2) {
        transition-delay: 0.125s;
      }
      &:nth-child(3) {
        transition-delay: 0.2s;
      }
    }
  }
  .hamburger-cross {
    position: absolute;
    height: 100%;
    width: 100%;
    transform: rotate(45deg);
    display: block;
    span {
      display: block;
      background: #516077;
      border-radius: 3px;
      -webkit-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
      &:nth-child(1) {
        height: 100%;
        width: 2px;
        position: absolute;
        top: 0;
        left: 10px;
        transition-delay: 0.3s;
      }
      &:nth-child(2) {
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: 10px;
        transition-delay: 0.4s;
      }
    }
  }
}
.collapsed {
  .menu_toggle {
    .hamburger {
      span {
        width: 100%;
        &:nth-child(1) {
          transition-delay: 0.3s;
        }
        &:nth-child(2) {
          transition-delay: 0.4s;
        }
        &:nth-child(3) {
          transition-delay: 0.5s;
        }
      }
    }
    .hamburger-cross {
      span {
        &:nth-child(1) {
          height: 0%;
          transition-delay: 0s;
        }
        &:nth-child(2) {
          width: 0%;
          transition-delay: 0.2s;
        }
      }
    }
  }
}

.collapsed-menu {
  max-height: 500px;
  width: 100%;
  overflow-y: auto;
  display: block;
  padding-bottom: 30px;
  .header-menu-wrapper {
    padding: 0 !important;
    justify-content: center;
  }
  ul {
    padding: 0 !important;
  }
  nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding-bottom: 20px;
  }
}
