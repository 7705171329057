.chart-main-wrapper {
  max-width: 1200px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;

  .chart-wrapper {
    padding: 20px;
    height: 350px;
  }

  .chart-bottom-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 30px 40px;
    span {
      display: inline-block;
      margin: 0 15px;
      font-size: 16px;
    }
  }
}
