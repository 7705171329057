.total-count-wrapper {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  pre {
    margin-bottom: 0;
  }
}

.csv-drive-btns-wrapper {
  align-items: center;
  justify-content: flex-end;
  display: flex;

  & > :first-child {
    margin-right: 20px;
  }
}

.edit-modal-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  min-height: 200px;
  background-color: #fff;
  padding: 20px;

  .edit-modal-container-body {
    display: flex;
    flex-direction: column;
    .inputs-main-wrapper {
      min-height: 150px;

      .selects-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-right: 50px;
        width: 100%;

        .count-label {
          margin-bottom: 17px;
        }

        label {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 500;
        }
      }
      .email-label {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 17px;
      }
    }
    .btns-block-wrapper {
      display: flex;
      justify-content: flex-end;
      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 200px;
      }
    }
  }
}
