@import 'styles/themeAndVariable.scss';

#popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  .custom-modal-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    min-height: 200px;
    text-align: center;
    background-color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal__body {
      word-break: break-all;
      padding: 15px;

      .icon-wrapper {
        display: inline-block;
      }
      .close__btn {
        position: absolute;
        top: -10px;
        right: -10px;
        background: $color-white;
        border: none;
        width: 45px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      }

      .modal-title {
        margin-top: 10px;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        margin: auto;
        width: 80%;
      }
      .modal-msg {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.25px;
        text-align: center;
      }
      .panel__btns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        .panel__btn {
          width: 130px;
        }
      }
    }
  }
}
