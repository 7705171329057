$themes: (
  light: (
    colorText: #000,
    colorTextSecondary: #585f67,
    colorBackground: #f8f8fa,
    colorBackgroundBody: #fff,
    colorHover: #fafbfe,
    colorBorder: #d7e0e8,
    colorFooterTop: #32363b,
    colorFooterBottom: #212121,
    // logoImg: url('src')
  ),
  dark: (
    colorText: #eff2f7,
    colorTextSecondary: #888c98,
    colorBackground: #222736,
    colorBackgroundBody: #2a3142,
    colorHover: #222736,
    colorBorder: #353e53,
    colorFooterTop: #32363b,
    colorFooterBottom: #212121,
    // logoImg: url('src')
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

$color-primary: #bd55ce;
$color-hover: #6849c6;
$color-white: #fff;

$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);
$color-border: #d9e3ea;

$color-tooltip-background: #f2eaf8;

$color-yellow: #fba92e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ed5565;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blue-hover: darken($color-blue, 10%);

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);
