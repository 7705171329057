.countries-chart .recharts-legend-wrapper {
  top: 80px !important;
  @media (max-width: 500px)  {
    ul {
      padding-left: 5px !important;
    }
  }
}

.MuiPaper-rounded {
  border: 1px solid rgba(0, 40, 100, 0.12);
  box-shadow: none !important;
}
.pie-card-header {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 40, 100, 0.12);
  display: flex;
  a {
    font-weight: 500;
    font-size: 18px;
    margin: auto 0;
    text-decoration: none;
    color: #000;
  }
  .reload-icon {
    margin: auto 0 auto auto;
    cursor: pointer;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.pie-card-footer {
    padding: 20px 20px 0 20px;
    border-top: 1px solid rgba(0, 40, 100, 0.12);
}