@mixin ellipsis($width) {
  width: $width;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-datepicker-popper {
  z-index: 20 !important;
}
